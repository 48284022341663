/* eslint-disable jsx-a11y/anchor-is-valid*/
import React from "react";
import { DataConstant } from "../../constants/dataConstant";
import store from "../../../redux/store";
const { REACT_APP_IMAGEURL } = process.env;

const {
  widget: { businessDetail,serviceProviderProfileDetail, isBusiness }
} = store.getState();

const profilePic = isBusiness ? (businessDetail?.businessProfilePic) : (serviceProviderProfileDetail?.serviceProviderProfileImage);
const name = isBusiness ? (businessDetail?.businessName) : (serviceProviderProfileDetail?.serviceProviderName);

const BusinessAndSpProfile = () => {

  return (
    <div className="cm-up-res-wrapper d-flex d-sm-none align-items-center">
      <div className="cm-widget-img-block mx-auto">
        <img className="img-fluid rounded-circle" src={profilePic ? profilePic : REACT_APP_IMAGEURL + DataConstant.media.defaultUserPic} alt="" />
      </div>
      <h4
        className="font-size-lg text-dark  mb-0 px-3"
      >
        {name}
      </h4>
    </div>
  );
};
export default BusinessAndSpProfile;
