import React, { Suspense } from "react";
import { Redirect, Switch } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../_metronic/layout";
import { RoutesList } from "./routeList";
import { useSelector, shallowEqual } from "react-redux";
import { ActionLoadingDialog } from "../app/components/loader";
import WidgetBanner from "./modules/widget/widget-banner";

export default function BasePage() {
  const { actionLoader } = useSelector(
    (state) => ({ actionLoader: state.loader.actionLoader }),
    shallowEqual
  );
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      {actionLoader && <ActionLoadingDialog isLoading={actionLoader} />}
      <Switch>
        {
          /* Redirect from root URL to /dashboard. */
          // <Redirect exact from="/" to="/dashboard" />
        }
        {RoutesList.map((route) => {
          const showBanner = !route?.noBanner;
          return (
            <ContentRoute
              key={route.path}
              exact
              path={route.path}
              component={route.component}
              {...(showBanner ? { banner: WidgetBanner } : {})}
            />
          );
        })}
        <Redirect to="error/error-v1" />
      </Switch>
    </Suspense>
  );
}
