import React, { useEffect, useState,useRef } from "react";
import { Dropdown } from "react-bootstrap";
import { returnToken } from "../../../utils/helper";
import { actionTypes } from "./_redux/widgetRedux";
import { actionTypes as consultationActionTypes } from "./_redux/consultationRedux";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { signOut, signIn } from "../../../utils/authProvider";
import { useIntl } from "react-intl";
import BusinessAndSpProfile from "./widget-businessAndSpProfile";
import styles from "./widget-profileMenu.module.css"
import { DataConstant } from "../../constants";

const WidgetprofileMenu = ({ history, classText }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [isToggleOpen, setIsToggleOpen] = useState(false);
  const dropdownRef = useRef(null);

  const handleToggle = () => {
    setIsToggleOpen(!isToggleOpen);
  }

  const logout = () => {
    dispatchClearBookingDetail();
    clearConsultationDetail();
    dispatch({
      type: actionTypes.ClearUserDetail,
    });
    signOut();
  };


  const goToHomePage = () => {
    dispatchClearBookingDetail();
    clearConsultationDetail();
    dispatch({
      type: actionTypes.SetWidgetTab,
      payload: {
        currentTab: DataConstant.tabs.widgetMain
      },
    });
    history.push(`${returnToken()}`);
  };

  const clearConsultationDetail = () => {
    dispatch({
      type: consultationActionTypes.setClearConsultationDetail,
    });
  };

  const { isAuthorized, isSigninInProgress, clientName } = useSelector(
    ({ auth }) => ({
      isAuthorized: auth.user != null,
      isSigninInProgress: auth.isSigninInProgress,
      clientName: auth.user != null ? auth.user.name : "",
    }),
    shallowEqual
  );

  const dispatchClearBookingDetail = () => {
    dispatch({
      type: actionTypes.ClearBookingDetail,
    });
  };

  const setClearConsultationDetail = () => {
    dispatch({
      type: consultationActionTypes.setClearConsultationDetail,
    });
  };

  const onAppoinmentClick = () => {
    setConsultationListPage();
    setClearConsultationDetail();
    history.push(`${returnToken("my-Appointments")}`);
  }

  const setConsultationListPage = () => {
    dispatch({
      type: actionTypes.setConsultationListPage,
      payload: {
        isBackFromConsultationListPage: false,
      },
    });
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsToggleOpen(false);
    }
  };
  
  return (
    <>
      {isAuthorized && isSigninInProgress === false ? (
        <div onClick={handleToggle} ref={dropdownRef} className={styles.dropdownContainer}>
        <Dropdown className={classText ? `${classText}` : "cm-user-profile"} show={isToggleOpen}>
          <BusinessAndSpProfile/>
          <Dropdown.Toggle variant="success" id="dropdown-basic" className={`h-100 align-self-center ${styles.toggleContainer}`}>
            {clientName}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item onClick={() => goToHomePage()}>{intl.formatMessage({ id: "ProfileMenu.Home" })}</Dropdown.Item>
            <Dropdown.Item onClick={() => history.push(`${returnToken("profile")}`)}>{intl.formatMessage({ id: "ProfileMenu.Profile" })}</Dropdown.Item>
            <Dropdown.Item onClick={onAppoinmentClick}>{intl.formatMessage({ id: "ProfileMenu.MyAppointments" })}</Dropdown.Item>
            <Dropdown.Item onClick={() => logout()}>{intl.formatMessage({ id: "ProfileMenu.Logout" })}</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
        </div>
      ) : (
        <div onClick={handleToggle} ref = {dropdownRef} className={styles.dropdownContainer}>
        <Dropdown className={classText ? `${classText}` : "cm-user-profile"}  show={isToggleOpen}>
          <BusinessAndSpProfile/>
          <Dropdown.Toggle variant="success" id="dropdown-basic" className={`h-100 align-self-center ${styles.toggleContainer}`}>
            {intl.formatMessage({ id: "Common.HelloGuest" })}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item onClick={() => goToHomePage()}>{intl.formatMessage({ id: "ProfileMenu.Home" })}</Dropdown.Item>
            <Dropdown.Item onClick={signIn}>{intl.formatMessage({ id: "ProfileMenu.Login" })}</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
        </div>
      )}
    </>
  );
};

export default WidgetprofileMenu;
