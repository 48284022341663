import { lazy } from "react";

export const RoutesList = [
  {
    path: "/builder",
    component: lazy(() => import("./pages/BuilderPage")),
  },
  {
    path: "/demo",
    component: lazy(() => import("./modules/demo/demo")),
  },
  {
    path: "/widget",
    component: lazy(() => import("./modules/widget/widget-ui")),
    noBanner: true,
  },
  {
    path: "/widget-business/:code",
    component: lazy(() => import("./modules/widget/widget-verifyBusiness")),
  },
  {
    path: "/widget/serviceProviderProfile/:id",
    component: lazy(() => import("./modules/widget/widget-serviceProviderProfile")),
  },
  {
    path: "/widget/otherTimeSlot/:id",
    component: lazy(() => import("./modules/widget/widget-otherTimeSlot")),
    noBanner: true,
  },
  {
    path: "/widget/signup",
    component: lazy(() => import("./modules/widget/widget-signup")),
    noBanner: true
  },
  {
    path: "/widget/medicalForm",
    component: lazy(() => import("./modules/widget/widget-medicalConsentForm")),
    noBanner: true,
  },
  {
    path: "/widget/serviceProviderReviews/:id",
    component: lazy(() => import("./modules/widget/widget-serviceProviderReviews")),
  },
  {
    path: "/widget/404",
    component: lazy(() => import("./modules/widget/widget-404")),
  },
  {
    path: "/widget/401",
    component: lazy(() => import("./modules/widget/widget-401")),
  },
  {
    path: "/widget/paymentPage",
    component: lazy(() => import("./modules/widget/widget-payment")),
  },
  {
    path: "/widget/profile",
    component: lazy(() => import("./modules/widget/widget-viewProfile")),
    noBanner: true,
  },
  {
    path: "/widget/booking-list",
    component: lazy(() => import("./modules/widget/widget-bookinglist/widget-bookingList")),
  },
  {
    path: "/widget/psf-booking-list",
    component: lazy(() => import("./modules/widget/widget-bookinglist/widget-psf-bookingList")),
  },
  {
    path: "/widget/servicePackForms/consentForImages",
    component: lazy(() => import("./modules/widget/servicePackForms/consentForImagesForm")),
  },
  {
    path: "/widget/servicePackForms/hipaaConsent",
    component: lazy(() => import("./modules/widget/servicePackForms/hippaConsentForm")),
  },
  {
    path: "/widget/servicePackForms/individualMediaRelease",
    component: lazy(() => import("./modules/widget/servicePackForms/individualMediaReleaseForm")),
  },
  {
    path: "/widget/servicePackForms/hipaaAuthorizationform",
    component: lazy(() => import("./modules/widget/servicePackForms/hippaAuthorizationform")),
  },

  {
    path: "/widget/servicePackForms/nadInformedConsent",
    component: lazy(() => import("./modules/widget/servicePackForms/nadConsentForm")),
  },
  {
    path: "/widget/servicePackForms/nadIntakeForm",
    component: lazy(() => import("./modules/widget/servicePackForms/nadIntakeForm")),
  },
  {
    path: "/widget/servicePackForms/carnitineIntakeForm",
    component: lazy(() => import("./modules/widget/servicePackForms/carnitineIntakeForm")),
  },
  {
    path: "/widget/servicePackForms/ivBoosterConsent",
    component: lazy(() => import("./modules/widget/servicePackForms/ivBoosterConsentForm")),
  },
  {
    path: "/widget/servicePackForms/covidConsent",
    component: lazy(() => import("./modules/widget/servicePackForms/covidConsentForm")),
  },
  {
    path: "/widget/servicePackForms/scleroTherapyConsent",
    component: lazy(() => import("./modules/widget/servicePackForms/seleroTherapyConsentForm")),
  },
  {
    path: "/widget/servicePackForms/polidocanolInjectionInformedConsent",
    component: lazy(() => import("./modules/widget/servicePackForms/polodocanolInjectionInformedConsentForm")),
  },
  {
    path: "/widget/servicePackForms/infusionConsent",
    component: lazy(() => import("./modules/widget/servicePackForms/infusionConsent")),
  },
  {
    path: "/widget/servicePackForms/microneedlineConsent",
    component: lazy(() => import("./modules/widget/servicePackForms/microneedlingConsent")),
  },
  {
    path: "/widget/servicePackForms/prpConsent",
    component: lazy(() => import("./modules/widget/servicePackForms/prpInformedConsent")),
  },
  {
    path: "/widget/servicePackForms/dermalFillerConsent",
    component: lazy(() => import("./modules/widget/servicePackForms/darmalFillerConsent")),
  },
  {
    path: "/widget/servicePackForms/patientAttestation",
    component: lazy(() => import("./modules/widget/servicePackForms/patientAttestationForm")),
  },
  {
    path: "/widget/servicePackForms/informedConsentForNeurotoxin",
    component: lazy(() => import("./modules/widget/servicePackForms/informedConsentForm")),
  },
  {
    path: "/widget/servicePackForms/neurotoxinInjectablesIntake",
    component: lazy(() => import("./modules/widget/servicePackForms/neurotoxinInjectBriefIntake")),
  },
  {
    path: "/widget/servicePackForms/mesotherapyConsent",
    component: lazy(() => import("./modules/widget/servicePackForms/mesotherapyConsentForm")),
  },
  {
    path: "/widget/servicePackForms/questionnaireForNeurotoxinInjections",
    component: lazy(() => import("./modules/widget/servicePackForms/questionnaireForNeurotoxinInjectionsForm")),
  },
  {
    path: "/widget/servicePackForms/zofran",
    component: lazy(() => import("./modules/widget/servicePackForms/zofranIntakeForm")),
  },
  {
    path: "/widget/servicePackForms/ivPatientIntakeForm",
    component: lazy(() => import("./modules/widget/servicePackForms/ivPatientIntakeForm")),
  },
  {
    path: "/widget/servicePackForms/ivGoodFaithExam",
    component: lazy(() => import("./modules/widget/servicePackForms/ivGoodFaithExamForm")),
  },
  {
    path: "/widget/servicePackForms/fillerMedicalHistory",
    component: lazy(() => import("./modules/widget/servicePackForms/fillerMedicalHistoryForm")),
  },
  {
    path: "/widget/servicePackForms/toradol",
    component: lazy(() => import("./modules/widget/servicePackForms/toradolForm")),
  },
  {
    path: "/widget/booking-detail/:id",
    component: lazy(() => import("./modules/widget/widget-bookingConfirmation")),
    noBanner: true,
  },
  {
    path: "/widget/psf-booking-detail/:id",
    component: lazy(() => import("./modules/widget/widget-psfBookingDetails")),
    noBanner: true
  },
  {
    path: "/widget/order-medication/:id",
    component: lazy(() => import("./modules/widget/widget-orderMedication")),
    noBanner: true,
  },
  {
    path: "/widget/consultation",
    component: lazy(() => import("./modules/widget/consultation/hcp-consultation")),
    noBanner: true,
  },
  {
    path: "/widget/consultation/otherTimeSlot/:id",
    component: lazy(() => import("./modules/widget/consultation/hcp-otherTimeSlot")),
    noBanner: true,
  },
  {
    path: "/widget/consultation/hcpProfile/:id",
    component: lazy(() => import("./modules/widget/consultation/hcp-profile")),
  },
  {
    path: "/widget/booking-cancel/:id",
    component: lazy(() => import("./modules/widget/widget-bookinglist/widget-bookingCancel")),
    noBanner: true,
  },
  {
    path: "/widget/documents",
    component: lazy(() => import("./modules/widget/widget-uploadDocument/widget-documentList")),
  },
  {
    path: "/widget/add-Documents",
    component: lazy(() => import("./modules/widget/widget-uploadDocument/widget-addDocuments")),
  },
  {
    path: "/widget/viewEdit-Documents",
    component: lazy(() => import("./modules/widget/widget-uploadDocument/widget-viewEditDocuments")),
  },
  {
    path: "/widget/procedures/:id",
    component: lazy(() => import("./modules/widget/servicePackForms/clientProcedures")),
  },
  {
    path: "/widget/videoConsultation/:id",
    component: lazy(() => import("./modules/widget/videoConsultation/widget-consultation")),
    noBanner: true,
  },  
  {
    path: "/widget/my-Appointments",
    component: lazy(() => import("./modules/widget/consultationList/widget-myAppoinments")),
    noBanner: true,
  },
  {
    path: "/widget/consultation-list",
    component: lazy(() => import("./modules/widget/consultationList/widget-consultationList")),
  },
  {
    path: "/widget/consultation/assignServiceToClient",
    component: lazy(() => import("./modules/widget/consultation/assignServiceToClient")),
  },
  {
    path: "/widget/consultation/addClient",
    component: lazy(() => import("./modules/widget/consultation/addClient")),
  },
  {
    path: "/widget/consultation/clientChoose",
    component: lazy(() => import("./modules/widget/consultation/clientChoose")),
  },
  {
    path: "/widget/consultation/clientExists",
    component: lazy(() => import("./modules/widget/consultation/clientExists")),
  },
  {
    path: "/widget/consultation/assignClients",
    component: lazy(() => import("./modules/widget/consultation/assignClients")),
  },
  {
    path: "/widget-verifyMobileMedIvBusiness/:code",
    component: lazy(() => import("./modules/widget/widget-verifyMobileMedIVBusinessService")),
  },
  {
    path: "/widget-verifyMobileMedIvBusiness/:code/:serviceCode",
    component: lazy(() => import("./modules/widget/widget-verifyMobileMedIVBusinessService")),
  },
  
  {
    path: "/widget/consultation-detail/:type/:id",
    component: lazy(() => import("./modules/widget/consultationList/serviceConsultationView")),
    noBanner: true,
  },
  {
    path: "/widget/servicePackForms/dripbar/nadInformedConsent",
    component: lazy(() => import("./modules/widget/servicePackForms/nadInformedConsentFormDripbar")),
  },
  {
    path: "/widget/servicePackForms/semaglutideInformedConsent",
    component: lazy(() => import("./modules/widget/servicePackForms/semaglutideInformedConsent")),
  },
  {
    path: "/widget/servicePackForms/semaglutidescreeningtool",
    component: lazy(() => import("./modules/widget/servicePackForms/semaglutideScreeningTool")),
  }, 
  {
    path: "/widget/servicePackForms/coQ10ScreeningTool",
    component: lazy(() => import("./modules/widget/servicePackForms/coQ10ScreeningTool")),
    },
    {
        path: "/widget/servicePackForms/aLAConsent",
        component: lazy(() => import("./modules/widget/servicePackForms/aLAConsentFormDripbar")),
    
  },
  {
    path: "/widget/servicePackForms/IVPepcidScreeningToolForm",
    component: lazy(() => import("./modules/widget/servicePackForms/ivPepcidScreeningTool")),
  },
  {
      path: "/widget/profileEdit",
      component: lazy(() => import("./modules/widget/widget-ProfileEdit")),
      noBanner: true,
  },
  {
    path: "/widget/servicePackForms/weightlossquestionnaireforphytmedspa",
    component: lazy(() => import("./modules/widget/servicePackForms/weight-loss-questionnaire")),
  },
  {
    path: "/widget/servicePackForms/informedconsentformforphytmedspa",
    component: lazy(() => import("./modules/widget/servicePackForms/informed-consent")),
  },
  {
    path: "/widget/servicePackForms/hipaaformforphytmedspa",
    component: lazy(() => import("./modules/widget/servicePackForms/hipaa-form")),
  },
  {
    path: "/widget/servicePackForms/returnrefundpolicyforphytmedspa",
    component: lazy(() => import("./modules/widget/servicePackForms/return-and-refund-policy")),
  },
];
