import React from "react";
import styles from "./widget-ui.module.css"
import WidgetprofileMenu from "./widget-profileMenu";
import { useSelector } from 'react-redux';

const WidgetBanner = ({history}) => {
    const widgetDetail = useSelector(state => state.widget);

    return (
        <div style={{backgroundColor: '#f7f7f7'}}>
            <div style={{backgroundColor: widgetDetail?.businessDetail?.widgetBannerColor}} className={`${styles.banner} primary-color`}/> 
            <div className={`position-absolute ${styles.profileMenu}`}>
                <WidgetprofileMenu
                    history={history}
                    classText={"cm-user-profile dropdown d-none d-flex d-sm-flex"}
                    businessDetail={widgetDetail?.businessDetail}
                />
            </div>
        </div>
    )
}

export default WidgetBanner;