import React from "react";
import { LoadingDialog } from "../../_metronic/_partials/controls";
import { Spinner } from "react-bootstrap";
import styles from "./style.module.css";
export function ActionLoadingDialog({ isLoading }) {
  return (
    isLoading &&
    <div className="cm-loader">
      <div className={styles.splash_screen}>
        <svg className="splash-spinner" viewBox="0 0 50 50">
          <circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="5"></circle>
        </svg>
      </div>
    </div>
  );
}
